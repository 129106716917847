<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <img src="@/assets/images/dundar-logo.png" width="155" alt="Dundar Law" style="max-width: 80%; height: 2em; margin-top: 1em; margin-left: 1.5em; vertical-align: middle; line-height: 100%; border: 0;">
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('login.page_not_found') }} 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          {{ $t('login.page_not_found_info') }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          @click="turnOnLogin()"
        >
          {{ $t('login.turn_on_login') }}
        </b-button>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
export default {
  methods: {
    turnOnLogin(){
      this.$router.push({name: 'Login'})
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
